import { observable, action } from "mobx";

class User {
  @observable _token = "";
  @observable _username = "";
  @observable _avatar = "";
  @observable _nickname = "";
  @observable _gender = null;
  @observable _birthday = 0;

  get token() {
    return this._token;
  }
  set token(value) {
    this._token = value;
  }
  get username() {
    return this._username;
  }
  set username(value) {
    this._username = value;
  }
  get avatar() {
    return this._avatar;
  }
  set avatar(value) {
    this._avatar = value;
  }
  get nickname() {
    return this._nickname;
  }
  set nickname(value) {
    this._nickname = value;
  }
  get gender() {
    return this._gender;
  }
  set gender(value) {
    this._gender = value;
  }
  get birthday() {
    return this._birthday;
  }
  set birthday(value) {
    this._birthday = value;
  }

  @action setInfo(info) {
    const { id, username, avatar, nickname, gender, birthday } = info;

    this._id = id;
    this._username = username;
    this._avatar = avatar;
    this._nickname = nickname;
    this._gender = gender;
    this._birthday = birthday;
    this._token = "token";
  }

  @action logout() {
    this._token = "";
    this._username = "";
    this._avatar = "";
    this._nickname = "";
    this._gender = null;
    this._birthday = 0;
  }

  //   @action async fetchInfo() {
  //     try {
  //       const { info } = await Api.userInfo();

  //       const { id, username, avatar, nickname, gender, birthday } = info;

  //       this._id = id;
  //       this._username = username;
  //       this._avatar = avatar;
  //       this._nickname = nickname;
  //       this._gender = gender;
  //       this._birthday = birthday;
  //     } catch (error) {}
  //   }
}

const user = new User();

export default user;
