import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';

import {getId} from './account';

export const pay = ({price, orderSn, payTypeId}) => {

    return getId().then(id => {
        return http({
            url: Global.PAY_BASE_URL + '/pay',
            method: Method.POST,
            headers: {
                frontUserId: id,
                protocol: window.location.protocol,
                domain: window.location.host
            },
            params: {
                price,
                orderSn,
                payTypeId
            }
        }).then(res => res.data);
    })
}

export const paySocket = ({orderId}) => {
    return `${Global.PAY_BASE_URL}/api/socket/pay/${orderId}`;
};