import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

import ImgEnum from 'enum/ImgEnum';

/**
 * 商品楼层
 */
export const goodsFloorList = async () => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/api/index/web/index',
    data: {
      page: 1,
      size: 15
    }
  });

  const { index } = data;
  //   const {
  //     hotGoods: hotGoodsList,
  //     hotWord:hotWordList,
  //     selfRunImg: imgList
  //   } = selfRunInfo;

  let floorList = [];

  for (let key in index) {
    let floor = index[key][0];

    floorList.push(floor);
  }

  for (let i in floorList) {
    let floor = floorList[i];

    let originImgList = [...floor.imgList];

    let imgList = [];
    let rightDownImg = '';

    for (let j in originImgList) {
      const { type } = originImgList[j];

      if (type === ImgEnum.CATEGORY_RIGHT_IMG) {
        rightDownImg = originImgList[j];
      } else if (type === ImgEnum.CATEGORY_IMG) {
        imgList.push(originImgList[j]);
      }
    }

    floor.imgList = imgList;
    floor.rightDownImg = rightDownImg;
  }

  return {
    goodsFloorList: floorList
    // selfFloor: {
    //   hotGoodsList,
    //   hotWordList,
    //   imgList
    // }
  };
};

/**
 * 自营楼层
 * @param {*} param0
 */
export const selfFloor = async ({ pageNum, pageSize }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/api/index/self/run/index',
    data: {
      page: pageNum,
      size: pageSize
    }
  });

  let {
    hotGoods: hotGoodsList,
    hotWord: hotWordList,
    selfRunImg: originImgList
  } = data;

  let imgList = [];
  let leftImgList = [];

  for (let i in originImgList) {
    const { type } = originImgList[i];

    if (
      type === ImgEnum.SELF_RUN_LEFT_IMG ||
      type === ImgEnum.SELF_RUN_LEFT_IMG2 ||
      type === ImgEnum.SELF_RUN_RIGHT_IMG1 ||
      type === ImgEnum.SELF_RUN_RIGHT_IMG2
    ) {
      imgList.push(originImgList[i]);
    } else if (type === ImgEnum.SELF_RUN_INDEX) {
      leftImgList.push(originImgList[i]);
    }
  }

  return {
    selfFloor: {
      hotGoodsList,
      hotWordList,
      imgList,
      leftImgList
    }
  };
};
