import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

export const recommendList = async ({ pageSize, pageNum }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/api/index/look/around',
    data: {
      page: pageNum,
      size: pageSize
    }
  });

  const { lookAroundGoodsList: list } = data;
  const { totalNum } = data;

  return {
    list,
    totalNum
  };
};
