import {observable, action} from "mobx";
import * as Api from "api/api";
import ImgEnum from "enum/ImgEnum";

class App {
    @observable _concat = null;
    @observable _logo = null;
    @observable _app = null;
    @observable _home = null;
    @observable _contact = null;
    @observable _website = null;
    @observable _recordUrl = null;

    get concat() {
        return this._concat;
    }

    set concat(value) {
        this._concat = value;
    }

    get logo() {
        return this._logo;
    }

    set logo(value) {
        this._logo = value;
    }

    get app() {
        return this._app;
    }

    set app(value) {
        this._app = value;
    }

    get home() {
        return this._home;
    }

    set home(value) {
        this._home = value;
    }

    get contact() {
        return this._contact;
    }

    set contact(value) {
        this._contact = value;
    }

    get website() {
        return this._website;
    }

    set website(value) {
        this._website = value;
    }

    get recordUrl() {
        return this._recordUrl;
    }

    set recordUrl(value) {
        this._recordUrl = value;
    }

    @action fetchConcat() {
        Api.contactInfo().then(info => {
            this._concat = info;
        });
    }

    @action
    async fetchLogo() {
        try {
            const {info} = await Api.imgInfo({type: ImgEnum.INDEX_LOGO});

            this._logo = info;
        } catch (error) {
        }
    }

    @action
    async fetchApp() {
        try {
            const {info: app} = await Api.app();
            this._app = app;
        } catch (error) {
        }
    }

    @action
    async fetchHome() {
        try {
            const {info: home} = await Api.home();
            this._home = home;
        } catch (error) {
        }
    }

    @action
    async fetchContact() {
        try {
            const {info: contact} = await Api.contact();
            this._contact = contact;
        } catch (error) {
            debugger
        }
    }

    @action
    async fetchWebsite() {
        try {
            const {info: website} = await Api.website();
            this._website = website;
        } catch (error) {
        }
    }

    @action fetchRecordUrl(){
        Api.recordUrl().then(recordUrl=>{
            this._recordUrl = recordUrl;
        })
    }

    @action fetchJson() {
        try {
            this.fetchConcat();
            this.fetchLogo();
            this.fetchApp();
            this.fetchHome();
            this.fetchContact();
            this.fetchWebsite();
            this.fetchRecordUrl();
        } catch (error) {
        }
    }
}

const app = new App();

export default app;
