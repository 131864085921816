import cart from './cart/cart-store'
import merchant from './merchant/merchant-store'
import help from './help/help-store'
import service from './service/service-store'
import goodsList from './goods-list/goods-list-store'
import login from './login/login-store'
import register from './register/register-store'
import user from './user/user-store'
import qrCode from './qr-code/qr-code-store'
import menu from './menu/menu-store'
import app from './app/app-store'
import seo from './seo/seo-store'
import adv from './adv/adv-store'
import order from './order/order-store'
import orderList from './order/order-list-store';
import addressList from './address/address-list-store';
import orderPay from './order/order-pay-store';
import shopStreet from './shop-street/shop-street-store';

export default {
    cart,
    merchant,
    help,
    service,
    goodsList,
    login,
    register,
    user,
    qrCode,
    menu,
    app,
    seo,
    adv,
    order,
    orderList,
    addressList,
    orderPay,
    shopStreet
}
