import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

/**
 * 首页秒杀商品接口
 * @param {*} param0
 */
export const homeSecKillGoodsList = async ({ pageSize, pageNum }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/sec/kill/list',
    data: {
      page: pageNum,
      size: pageSize
    }
  });

  const list = data.msg.map(item => {
    const {
      goodsId: id,
      startTime,
      endTime,
      goodsName,
      originPrice,
      currentPrice,
      mainPic: img,
      totalStocks: stock,
      secKillPrice,
      shopId,
      pddShop
    } = item;

    return {
      id,
      startTime,
      endTime,
      goodsName,
      originPrice,
      currentPrice,
      img,
      stock,
      secKillPrice,
      shopId,
      pddShop
    };
  });

  return {
    list
  };
};

export const secKillList = async ({ pageSize, pageNum, keyword }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/sec/kill/list',
    data: {
      page: pageNum,
      size: pageSize,
      keyword
    }
  });

  const list = data.msg.map(item => {
    const {
      goodsId:id,
      startTime,
      endTime,
      goodsName,
      originPrice,
      currentPrice,
      mainPic,
      totalStocks,
      secKillPrice,
      shopId,
      pddShop
    } = item;

    return {
      id,
      startTime,
      endTime,
      goodsName,
      originPrice,
      currentPrice,
      mainPic,
      totalStocks,
      secKillPrice,
      shopId,
      pddShop
    };
  });

  return { list };
};
