import * as ActionType from 'utils/ActionType';

const type = {
  SET_SEC_KILL_GOODS_LIST: 'SET_SEC_KILL_GOODS_LIST',
  SET_SELF_FLOOR: 'SET_SELF_FLOOR',
  SET_GOODS_FLOOR_LIST: 'SET_GOODS_FLOOR_LIST',
  SET_GUESS_LIST: 'SET_GUESS_LIST',
  SET_LOOK_AROUND_LIST: 'SET_LOOK_AROUND_LIST',
  PUSH_LOOK_AROUND_LIST: 'PUSH_LOOK_AROUND_LIST',
  SET_NEWS_LIST: 'SET_NEWS_LIST',
  SET_SHOW_TOP_ADVERTISEMENT: 'SET_SHOW_TOP_ADVERTISEMENT',
  SET_CAN_SHOW_TOP_ADVERTISEMENT: 'SET_CAN_SHOW_TOP_ADVERTISEMENT',
  SET_BIG_BANNER: 'SET_BIG_BANNER',
  SET_CATEGORY_NAV: 'SET_CATEGORY_NAV'
};

ActionType.handleType({ type, namespace: 'home' });

export default type;
