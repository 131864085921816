import axios from "axios";
import Router from "router/router";
import * as antd from "antd";
// import qs from 'qs';
import loginStore from "store-mobx/login/login-store";

let http = axios.create();
let history = require("history").createBrowserHistory();

export const Header = {
  ContentType: "content-type"
};
export const ContentType = {
  FormData: "application/x-www-form-urlencoded;charset=UTF-8",
  JSON: "application/json"
};

//配置全局axios
// http.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded;charset=UTF-8";
http.defaults.headers.post["Content-Type"] = ContentType.JSON;
http.defaults.headers.put["Content-Type"] = ContentType.JSON;
// 带cookie请求
http.defaults.withCredentials = true;

// 添加请求拦截器
http.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // 请求已发出，但服务器响应的状态码不在 2xx 范围内
    if (error && error.response) {
      //   let data = error.response.data;
      const { response } = error;
      const { status, data, config,headers } = response;
      const { url } = config;


      if (status === 426) {
        loginStore.show = true;
      }else{
        let errorMessage = data.msg || data.ExceptionMsg || data;
        if (errorMessage) {
          antd.message.error(errorMessage);
        }
      }

    } else {
      console.log("Error:", error.message);
      //   loginStore.show = true;
    }

    return Promise.reject(error);
  }
);

export default http;
