import * as ActionType from 'utils/ActionType';

const type = {
  INIT: 'INIT',
  SET_GOODS: 'SET_GOODS',
  SET_SKU: 'SET_SKU',
  SET_IMG_LIST: 'SET_IMG_LIST',
  SET_HAS_COLLECT: 'SET_HAS_COLLECT',
  SET_COLLECT_ID: 'SET_COLLECT_ID',
  SET_GOODS_ATTRIBUTE_LIST: 'SET_GOODS_ATTRIBUTE_LIST',
  SET_SPECIFICATION_MAP_LIST: 'SET_SPECIFICATION_MAP_LIST',
  SELECT_SPECIFICATION: 'SELECT_SPECIFICATION',
  SET_COUNT: 'SET_COUNT',
  SET_RECOMMEND_LIST: 'SET_RECOMMEND_LIST',
  SET_LOOK_AROUND_LIST: 'SET_LOOK_AROUND_LIST',
  SET_PROMOTION_URL: 'SET_PROMOTION_URL',
  SET_DESCRIPTION: 'SET_DESCRIPTION',
  SET_IS_SEC_KILL: 'SET_IS_SEC_KILL',
  SET_SEC_KILL: 'SET_SEC_KILL',
  SET_LOADING:'SET_LOADING'
};

ActionType.handleType({ type, namespace: 'goods-info/' });

export default type;
