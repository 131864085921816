import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';

/**
 * 登录
 * @param {*} param0
 */
export const login = async ({username, password}) => {
    const {data} = await http({
        method: Method.POST,
        url: Global.BASE_URL + '/api/user/login',
        data: {
            username,
            password
        }
    });
};

/**
 * 退出登录
 */
export const logout = async () => {
    await http({
        method: Method.GET,
        url: Global.BASE_URL + '/api/user/logout'
    });
};

/**
 * 注册
 * @param {*} param0
 */
export const register = async ({
                                   username,
                                   password,
                                   avatar,
                                   nickname,
                                   gender,
                                   birthday
                               }) => {
    const formData = GetFormData({
        username,
        password,
        avatarFile: avatar,
        nickname,
        gender,
        birthday
    });

    await http({
        method: Method.POST,
        url: Global.BASE_URL + '/api/user/register',
        headers: {
            [Header.ContentType]: ContentType.FormData
        },
        data: formData
    });
};

/**
 * 修改密码
 * @param {*} param0
 */
export const modifyPassword = async ({verify, password, newPassword}) => {
    await http({
        method: Method.POST,
        url: Global.BASE_URL + '/api/user/modify/password',
        data: {
            code: verify,
            password: password,
            newPassword: newPassword
        }
    });
};

/**
 * 修改用户信息
 * @param {*} param0
 */
export const modifyUserInfo = async ({
                                         avatar,
                                         nickname,
                                         gender,
                                         birthday
                                     }) => {
    const formData = GetFormData({
        avatarFile: avatar,
        nickName: nickname,
        gender,
        birthday
    });

    await http({
        method: Method.PUT,
        url: Global.BASE_URL + '/api/user/update',
        headers: {
            [Header.ContentType]: ContentType.FormData
        },
        data: formData
    });
};

/**
 * 用户信息
 */
export const userInfo = async () => {
    const {data} = await http({
        method: Method.GET,
        url: Global.BASE_URL + '/api/user/info'
    });

    const {
        id,
        name: username,
        avatar,
        nickName: nickname,
        gender,
        birthday
    } = data.msg;

    let info = {id, username, avatar, nickname, gender, birthday};

    return {
        info
    };
};

/**
 * 联系人信息
 */
export const contactInfo = async () => {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + '/cart/business/contact'
    })
        .then(res => res.data)
        .then(data => {
            const {name, telPhone: phone} = data;

            return {
                name,
                phone
            };
        });
};

export const getId = () => {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + "/api/user/id"
    }).then(res => res.data)
}