import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import app from 'store/app/reducer';
import user from 'store/user/reducer';
import goodsInfo from 'store/goods-info/reducer';
import search from 'store/search/reducer';
import home from 'store/home/reducer';
import menu from 'store/menu/reducer';
import shopStreet from 'store/shop-street/reducer';
import shop from 'store/shop/reducer';
import news from 'store/news/reducer';
import secKill from 'store/sec-kill/reducer';

const persistConfig = {
  key: 'website',
  storage
};
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    app,
    user,
    goodsInfo,
    search,
    home,
    menu,
    shopStreet,
    shop,
    news,
    secKill
  })
);

let store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
let persistor = persistStore(store);

export { store, persistor };
