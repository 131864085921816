import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

// 商品详情
export const goodsInfo = async ({ goodsId, type }) => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + '/api/goods/detail',
    params: {
      goodsId,
      type
    }
  });

  const {
    product: sku,
    goods,
    userHasCollect: hasCollect,
    collectId,
    goodsPropList: goodsAttributeList,
    goodsSpecificationList: specificationMapList,
    goods_promotion_url: goodsPromotionUrl
  } = data;

  return {
    sku,
    goods,
    hasCollect,
    collectId,
    goodsAttributeList,
    specificationMapList,
    goodsPromotionUrl
  };
};
