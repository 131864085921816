import type from './action-type';
import { combineReducers } from 'redux';

const reducer = {};

// 秒杀
reducer.secKillGoodsList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_SEC_KILL_GOODS_LIST:
      return payload;
    default:
      return state;
  }
};

// 自营
reducer.selfFloor = (state = null, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_SELF_FLOOR:
      return payload;
    default:
      return state;
  }
};

// 商品楼层
reducer.goodsFloorList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_GOODS_FLOOR_LIST:
      return payload;
    default:
      return state;
  }
};

// 猜你喜欢
reducer.guessList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_GUESS_LIST:
      return payload;
    default:
      return state;
  }
};

// 随便逛逛
reducer.lookAroundList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_LOOK_AROUND_LIST:
      return payload;
    case type.PUSH_LOOK_AROUND_LIST:
      return [...state, ...payload];
    default:
      return state;
  }
};

//资讯公告
reducer.newsList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_NEWS_LIST:
      return payload;
    default:
      return state;
  }
};

// 是否显示顶层广告
reducer.showTopAdvertisement = (state = true, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_SHOW_TOP_ADVERTISEMENT:
      return payload;
    default:
      return state;
  }
};

// 是否可以显示顶层广告
reducer.canShowTopAdvertisement = (state = true, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_CAN_SHOW_TOP_ADVERTISEMENT:
      return payload;
    default:
      return state;
  }
};

// 大轮播
reducer.bigBanner = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_BIG_BANNER:
      return payload;
    default:
      return state;
  }
};

// 分类导航楼层
reducer.categoryNav = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_CATEGORY_NAV:
      return payload;
    default:
      return state;
  }
};

export default combineReducers({ ...reducer });
