import type from './action-type';
import {combineReducers} from 'redux';

const reducer = {};

reducer.goods = (state = null, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_GOODS:
            return payload;
        case type.INIT:
            return null;
        default:
            return state;
    }
};

reducer.sku = (state = null, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_SKU:
            return payload;
        case type.INIT:
            return null;
        default:
            return state;
    }
};

reducer.imgList = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_IMG_LIST:
            return payload;
        case type.INIT:
            return [];
        default:
            return state;
    }
};

reducer.hasCollect = (state = false, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_HAS_COLLECT:
            return payload;
        case type.INIT:
            return false;
        default:
            return state;
    }
};

reducer.collectId = (state = 0, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_COLLECT_ID:
            return payload;
        case type.INIT:
            return 0;
        default:
            return state;
    }
};

reducer.goodsAttributeList = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_GOODS_ATTRIBUTE_LIST:
            return payload;
        case type.INIT:
            return state;
        default:
            return state;
    }
};

reducer.specificationMapList = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_SPECIFICATION_MAP_LIST:
            return payload;
        // 选择规格
        case type.SELECT_SPECIFICATION: {
            let newState = {...state};

            const {key, index} = payload;

            newState[key].currentIndex = index;

            return newState;
        }
        case type.INIT:
            return [];
        default:
            return state;
    }
};

reducer.count = (state = 1, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_COUNT:
            return payload;
        case type.INIT:
            return 1;
        default:
            return state;
    }
};

reducer.recommendList = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_RECOMMEND_LIST:
            return payload;
        case type.INIT:
            return [];
        default:
            return state;
    }
};

reducer.lookAroundList = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_LOOK_AROUND_LIST:
            return payload;
        case type.INIT:
            return [];
        default:
            return state;
    }
};

reducer.promotionUrl = (state = null, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_PROMOTION_URL:
            return payload;
        case type.INIT:
            return null;
        default:
            return state;
    }
};

reducer.description = (state = null, action = {}) => {
    const {payload} = action;
    switch (action.type) {
        case type.SET_DESCRIPTION:
            return payload;
        case type.INIT:
            return null;
        default:
            return state;
    }
};

reducer.isSecKill = (state = false, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_IS_SEC_KILL:
            return payload;
        case type.INIT:
            return false;
        default:
            return state;
    }
};

export const secKill = {
    hour: 0,
    minute: 0,
    second: 0
};

reducer.secKill = (state = null, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.INIT:
            return null;
        case type.SET_SEC_KILL:
            return payload;
        default:
            return state;
    }
};

reducer.loading = (state = false, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_LOADING:
            return payload;
        default :
            return state;
    }
}

export default combineReducers({...reducer});
