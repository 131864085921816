import { observable } from 'mobx';

class Shop3 {
  @observable _categoryKeyword = '';
  @observable _shopName = '';
  @observable _shopUsername = '';
  @observable _shopPassword = '';
  @observable _ifAddSuffix = false;

  get shopPassword() {
    return this._shopPassword;
  }
  set shopPassword(value) {
    this._shopPassword = value;
  }

  get categoryKeyword() {
    return this._categoryKeyword;
  }
  set categoryKeyword(value) {
    this._categoryKeyword = value;
  }
  get shopName() {
    return this._shopName;
  }
  set shopName(value) {
    this._shopName = value;
  }
  get shopUsername() {
    return this._shopUsername;
  }
  set shopUsername(value) {
    this._shopUsername = value;
  }
  get ifAddSuffix() {
    return this._ifAddSuffix;
  }
  set ifAddSuffix(value) {
    this._ifAddSuffix = value;
  }
}

const shop3 = new Shop3();

export default shop3;
