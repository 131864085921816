import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

import ImgEnum from 'enum/ImgEnum';

/**
 * 获取顶部广告
 * @param {*} param0
 */
export const topAdv = async () => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/web/img/list',
    data: {
      page: 1,
      size: 1,
      type: ImgEnum.TOP_IMG
    }
  });

  const info = data.msg.objectList[0];

  return { info };
};

/**
 * 获取中部广告
 * @param {*} param0
 */
export const centerAdv = async () => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/web/img/list',
    data: {
      page: 1,
      size: 1,
      type: ImgEnum.CENTRAL_IMG
    }
  });

  const info = data.msg.objectList[0];

  return { info };
};

/**
 * 获取底部广告
 * @param {*} param0
 */
export const bottomAdv = async () => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/web/img/list',
    data: {
      page: 1,
      size: 1,
      type: ImgEnum.BOTTOM_IMG
    }
  });

  const info = data.msg.objectList[0];

  return { info };
};
